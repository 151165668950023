import React from 'react';
import { MainLayout } from '../../components/Layout';
import PartnersList from '../../components/Partners/PartnersList';
import { Link, withPrefix } from 'gatsby';
import imgBg from '../../assets/img/home/bg-wave.png';
import imgMobile from '../../assets/img/partners/mobile-quartier-event.png';
import imgIconChat from '../../assets/img/partners/icon-chat.svg';
import imgIconConnect from '../../assets/img/partners/icon-connect.svg';
import ReferencesSection from '../../components/Partners/ReferencesSection';
import Sogeprom from '../../assets/img/references/sogeprom.png';
import Kaufman from '../../assets/img/references/kaufman.png';
import Altarea from '../../assets/img/references/altarea.png';

export default () => {
  const referenceList = [
    {
      icon: Sogeprom,
      content: '',
    },
    {
      icon: Kaufman,
      content: '',
    },
    {
      icon: Altarea,
      content: '',
    },
  ];

  return (
    <MainLayout
      siteTitle={`Promoteurs immobilier - Découvrez nos offres`}
      siteDescription={`Découvrez nos offres déstinées aux promoteurs immobilier`}
    >
      <PartnersList selectedPartner="promoteurs" />

      <div
        className="bg-wave"
        style={{ backgroundImage: `url(${withPrefix(imgBg)})` }}
      >
        <section className="container mt-5">
          <div className="row">
            <div className="col-6 text-align-center hidden-sm">
              <img
                src={imgMobile}
                alt="Syndic de copropriété"
                className="max-width-400-lg"
              />
            </div>

            <div className="col-4">
              <div className="pl-5 pr-5">
                <div className="roboto font-size-xl bold pt-10">
                  Connectez vos résidents
                </div>
                <div className="text-color-purple-grey pt-2">
                  <ul className="bullets-list">
                    <li>
                      Innovez en tant que promoteur en offrant une expérience de
                      vie différente
                    </li>
                    <li>
                      Connectez en amont les futurs résidents afin de leur
                      permettre d’améliorer leur vie en communauté
                    </li>
                  </ul>
                </div>

                <div className="roboto font-size-xl bold pt-10 pt-5-md">
                  Concertation
                </div>
                <div className="text-color-purple-grey pt-2">
                  <ul className="bullets-list">
                    <li>
                      Co-construisez vos programmes à l’aide de sondages
                      digitalisés
                    </li>
                    <li>
                      Permettez à vos résidents d’organiser leur future vie en
                      communauté
                    </li>
                  </ul>
                </div>

                <div className="roboto font-size-xl bold pt-10 pt-5-md">
                  Logement connecté
                </div>
                <div className="text-color-purple-grey pt-5">
                  Proposez à vos résidents une gestion digitale des espaces
                  partagés à l'aide d'outils de réservation de salle ou de
                  planning partagés par exemple
                </div>

                <div className="ml-auto mr-auto pt-15 text-align-center hidden-sm">
                  <Link
                    to="/contact/"
                    state={{
                      contactType: 'partner',
                      contactSubType: 'promoteurs',
                    }}
                    className="btn"
                  >
                    Nous contacter
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row hide show-md">
            <div className="col-12 pt-5 pl-6-md pr-6-md text-align-center">
              <img src={imgMobile} alt="Syndic de copropriété" />
            </div>
          </div>

          <div className="row pb-10 hide show-md">
            <div className="col-12 ml-auto mr-auto text-align-center">
              <Link
                to="/contact/"
                state={{ contactType: 'partner', contactSubType: 'promoteurs' }}
                className="btn"
              >
                Nous contacter
              </Link>
            </div>
          </div>
        </section>
      </div>

      <section className="container mt-10 partners-advantages-list">
        <ul className="text-align-center">
          <li className="mb-4">
            <div className="advantage-icon">
              <img src={imgIconChat} alt="Simplifiez" />
            </div>
            <div className="pt-3 pl-3 pr-3">
              <strong>Simplifiez</strong> la communication avec vos résidents
            </div>
          </li>
          <li className="mb-4 ml-5">
            <div className="advantage-icon">
              <img src={imgIconConnect} alt="Proposez" />
            </div>
            <div className="pt-3 pl-3 pr-3">
              <strong>Proposez</strong> un logement innovant
            </div>
          </li>
        </ul>
      </section>

      <ReferencesSection referenceList={referenceList} />
    </MainLayout>
  );
};
